'use client'
import Image from 'next/image'
import Link from 'next/link'
import { motion, useAnimation } from 'framer-motion'
import { useState, useEffect } from 'react'

const GlitchText = () => {
  return (
    <div className="glitch-wrapper flex justify-center items-center w-full overflow-hidden">
      <div className="glitch font-insanibu bg-gradient-to-r from-[#FFC09F] via-[#FFEE93] to-[#D0F0C0] text-transparent bg-clip-text text-[clamp(2rem, 8vw, 4rem)] leading-tight">
        GLYMPSE.FUN
      </div>
    </div>
  )
}

export default function Home() {
  const [ripplePosition, setRipplePosition] = useState({ x: 0, y: 0 })
  const rippleControls = useAnimation()
  const titleControls = useAnimation()
  const comingSoonControls = useAnimation()

  useEffect(() => {
    const animateTitle = async () => {
      await titleControls.start({
        backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
        transition: { duration: 10, ease: "linear", repeat: Infinity }
      })
    }
    animateTitle()

    comingSoonControls.start({
      scale: [1, 1.05, 1],
      transition: { duration: 2, ease: "easeInOut", repeat: Infinity }
    })
  }, [titleControls, comingSoonControls])

  const handleLogoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget
    const rect = button.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    setRipplePosition({ x, y })
    rippleControls.start({
      scale: [0, 1.5],
      opacity: [1, 0],
      transition: { duration: 0.4, ease: "easeOut" }
    })
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black overflow-hidden">
      <main className="text-center w-full max-w-screen-sm px-4">
        <motion.div 
          animate={titleControls}
          className="mb-4 w-full overflow-hidden"
        >
          <GlitchText />
        </motion.div>
        
        <motion.div 
          className="text-[clamp(1rem, 5vw, 2rem)] font-bold mb-8 border-2 border-[#FFC09F] px-4 py-2 inline-block"
          animate={comingSoonControls}
        >
          <span className="bg-gradient-to-r from-[#FFC09F] to-[#D0F0C0] text-transparent bg-clip-text">
            COMING SOON...
          </span>
        </motion.div>
        
        <div className="flex justify-center space-x-4 md:space-x-6 mb-8">
          <Link href="https://x.com/glympsedotfun" target="_blank" rel="noopener noreferrer" className="text-[clamp(1.5rem, 6vw, 3rem)] text-[#FFC09F] hover:text-[#D0F0C0] transition-colors">
            <i className="fab fa-twitter"></i>
          </Link>
          <Link href="https://t.me/glympsedotfun" target="_blank" rel="noopener noreferrer" className="text-[clamp(1.5rem, 6vw, 3rem)] text-[#FFC09F] hover:text-[#D0F0C0] transition-colors">
            <i className="fab fa-telegram-plane"></i>
          </Link>
          <Link href="https://discord.gg/EHA5kVuxYG" target="_blank" rel="noopener noreferrer" className="text-[clamp(1.5rem, 6vw, 3rem)] text-[#FFC09F] hover:text-[#D0F0C0] transition-colors">
            <i className="fab fa-discord"></i>
          </Link>
        </div>
        
        <motion.button 
          onClick={handleLogoClick}
          whileHover={{ scale: 1.05, rotate: 5 }}
          whileTap={{ scale: 0.95, rotate: -5 }}
          className="relative w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40 rounded-3xl overflow-hidden focus:outline-none mx-auto"
        >
          <Image
            src="/glympse-logo.png"
            alt="Glympse Logo"
            width={160}
            height={160}
            quality={100}
            className="object-cover w-full h-full"
          />
          <motion.div
            className="absolute inset-0 pointer-events-none"
            initial={{ scale: 0, opacity: 0 }}
            animate={rippleControls}
            style={{
              left: ripplePosition.x,
              top: ripplePosition.y,
              background: 'radial-gradient(circle, rgba(255,192,159,1) 0%, rgba(255,238,147,0.8) 50%, rgba(208,240,192,0.6) 100%)',
              width: 300,
              height: 300,
              borderRadius: '50%',
              marginLeft: -150,
              marginTop: -150,
            }}
          />
        </motion.button>
      </main>
    </div>
  )
}
